.table_container {
  margin: 30px 0;
}
.tr {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
}

.tr:last-child {
  border-bottom: none;
}

.th, .td {
  width: 100px;
  flex-grow: 1;
  padding: 20px 0;
  text-align: center;
}

.th {
  font-weight: 700;
}

.is_draggable {
  cursor: grab;
}

.arrow {
  position: absolute;
  right: -23px;
  top: 50%;
  transform: translateY(-30%);
}

.arrow_rotate {
  right: -15px;
  transform: translateY(-80%) rotate(180deg);
}